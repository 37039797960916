<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="uid" label="用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="goods_id" label="商品编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="order_no" label="订单号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="fee" label="支付金额" show-overflow-tooltip></el-table-column>
      <el-table-column prop="status" :formatter="e=>e.status == 3 ? '已核销完' : e.status == 2 ?'已支付': '未支付'" label="状态"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="pay_time" label="支付时间" show-overflow-tooltip></el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>

export default {
  components: {},
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      editBox: false,
      editForm: {}
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.shop.plugin.scanGoods.orderSearch({page:this.page}).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>